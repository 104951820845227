import { menuData } from "./menuData";
import { NavLink } from "react-router-dom";
import uuid from 'react-uuid'

const RightNav = ({ isActiveNav, setIsActiveNav, dropdownActive, setDropdownActive }) => {
  return (
      <>
        <nav className={isActiveNav ? "nav_bar active" : "nav_bar"}>
          <ul className="nav_menu">
            {
              menuData.map(item =>
                 item.arr.length === 0 ?
                     <li key={uuid()}>
                       <NavLink className="list_item" to={item.link} title={item.title + "페이지 이동"} onClick={() => {setIsActiveNav(false)}}>
                         {item.title}
                       </NavLink>
                     </li> :
                     <>
                       <li key={uuid()} className={dropdownActive ? "list_item dropdown active" : "list_item dropdown"} onClick={() => {setDropdownActive(prev => !prev)}}>
                         {item.title}
                       </li>
                       <ul className={dropdownActive ? "nav_dropdown active" : "nav_dropdown"}>
                         {
                           item.arr?.map(item =>
                               <li key={uuid()}>
                                 <NavLink to={item.link} title={item.title + "페이지 이동"} onClick={() => {setIsActiveNav(false)}}>
                                   {item.title}
                                 </NavLink>
                               </li>
                           )
                         }
                       </ul>
                     </>
              )
            }
          </ul>
        </nav>
      </>
  )
}

export default RightNav;