import React, { useEffect, useRef } from "react";

const UserAbout = () => {
  const mapRef = useRef(null);
  const { kakao } = window;
  const position = new kakao.maps.LatLng(37.51572362471226, 127.1082522813334);
  const mapOption = { center: position, level: 3 };

  useEffect(() => {
    const map = new kakao.maps.Map(mapRef.current, mapOption);
    // const marker = new kakao.maps.Marker({ position });
    const markerPosition = new kakao.maps.LatLng(
      37.51572362471226,
      127.1082522813334
    );

    const content = `
      <div class="customoverlay">
        <span>한경세무법인</span>
      </div>`;

    new kakao.maps.CustomOverlay({
      map: map,
      position: markerPosition,
      content: content,
      yAnchor: 1,
    });

    // marker.setMap(map);
  }, []);

  return (
    <>
      <section className="common about">
        <div className="w1756">
          <h2>회사소개</h2>
          <p>
            전문적이지만 고객이 충분이 이해할 수 있는 세무서비스를 만들어
            나가겠습니다.
          </p>
          <figure>
            <img
              src="/images/sub_img_about01.png"
              alt="자금출처"
              className="pc"
            />
            <img
              src="/images/sub_img_about01_1300.png"
              alt="자금출처"
              className="w1300"
            />
            <img
              src="/images/sub_img_about01_800.png"
              alt="자금출처"
              className="w800"
            />
            <img
              src="/images/sub_img_about01_400.png"
              alt="자금출처"
              className="w400"
            />
          </figure>
        </div>
      </section>
      <section className="greetings">
        <div className="top w100">
          <h2>
            <span className="blue">CEO</span> 인사말
          </h2>
        </div>
        <div className="background">
          <div className="container flex">
            <figure className="left">
              <img src="/images/sub_img_about02.png" alt="" />
            </figure>
            <div className="right">
              <div className="res1300">
                <figure className="disapp">
                  <img
                    src="/images/sub_img_about02.png"
                    alt=""
                    className="pc"
                  />
                  <img
                    src="/images/sub_img_about02_400.png"
                    alt=""
                    className="w400"
                  />
                </figure>
                <div className="topGreeting">
                  <h3>
                    안녕하세요, 대표세무사 <br className="w500" />
                    <span className="blue">손문옥</span>
                    입니다.
                  </h3>
                  <div className="pTot">
                    <p>한경세무법인 홈페이지 방문을 진심으로 환영합니다.</p>
                    <p>
                      저희 한경세무법인은 늘 고객과의 유기적인 소통을 통해
                      소극적인 서비스가 아닌 차별화된 능동적인 서비스로
                      <br className="br410" /> 고객 여러분이 사업에만 전념 할 수
                      있도록 힘쓰고 있습니다.
                    </p>
                    <p>
                      절세에 특화된 담당자를 통한 집중 케어 및 자문서비스와
                      최적의 절세를 위한 전략 수립을 통해 맞춤형 관리 서비스를
                      제공합니다.
                    </p>
                    <p>
                      앞으로도 한경세무법인은 이념을 바탕으로 고객과 함께
                      풍요롭고 지속 가능한 삶을 위해 더 큰 가치를 만들어 갈
                      것입니다.
                    </p>
                  </div>
                  <strong>
                    손문옥 <br />
                    MOONOK SON
                  </strong>
                </div>
              </div>
              <div className="line"></div>
              <div className="career">
                <h3>주요경력</h3>
                <ul className="flex">
                  <li>
                    <p>한경세무법인 대표세무사(現) </p>
                    <p>세무법인다솔 WM센터 세무사</p>
                    <p>미래에셋증권 WM비즈니스팀 세무컨설턴트</p>
                    <p>유안타증권 골드센터 강남점 Wealth Manager</p>
                    <p>공무원연금공단 제테크 강사</p>
                  </li>
                  <li>
                    <p>전국투자자교육협의회 강사</p>
                    <p>청소년금융교육협의회 세금부문 강사</p>
                    <p>한국가스안전공사 인재개발원 세금부문 강사</p>
                    <p>한국도로공사 인재개발원 세금부문 강사</p>
                    <p>금융투자절세가이드, 미래에셋절세가이드 1~3권 저자</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map">
        <div className="container">
          <h2>찾아오시는 길</h2>
          <div id="map" ref={mapRef}>
            <div className="mark"></div>
          </div>
          <div className="locationInfo">
            <ul className="detail flex">
              <li className="title">
                <h3>한경세무법인</h3>
              </li>
              <li className="right flex">
                <div className="address flex">
                  <span className="blue">
                    주<span className="space" />소
                  </span>
                  <div className="add">
                    <p>서울특별시 송파구 올림픽로 342(방이동), 아울타워 7층</p>
                    <p>
                      7th floors, 342 Olympic-ro, Songpa-gu, Seoul, <br />
                      Republic of Korea
                    </p>
                  </div>
                </div>
                <ul className="number">
                  <li className="flex">
                    <span className="blue">
                      전<span className="space" />화
                    </span>
                    <p>
                      <p className="lh13">
                        02-6925-3438 / 3498
                      </p>
                      <p className="mt5 lh13">
                        02-6952-3471 / 3472 / 3476
                      </p>
                    </p>
                  </li>
                  <li className="flex">
                    <span className="blue">
                      F<span className="space2" />A<span className="space2" />X
                    </span>
                    <p>
                      02-6442-6137
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserAbout;
