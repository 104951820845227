export const menuData = [
  {
    id: 1,
    title: "HOME",
    link: "/",
    arr: []
  },
  {
    id: 2,
    title: "기장서비스",
    link: "/accountBook",
    arr: []
  },
  {
    id: 3,
    title: "상속세서비스",
    link: "/inheritanceTax",
    arr: []
  },
  {
    id: 4,
    title: "증여세서비스",
    link: "/giftTax",
    arr: []
  },
  {
    id: 5,
    title: "양도소득세서비스",
    link: "/transferIncomeTax",
    arr: []
  },
  {
    id: 6,
    title: "자금출처",
    link: "/sources",
    arr: []
  },
  {
    id: 7,
    title: "회사소개",
    link: "/about",
    arr: []
  },
  {
    id: 8,
    title: "커뮤니티",
    link: "/community/taxCalculator",
    arr: [
          {id: 1, title: "세금계산기", link: "/community/taxCalculator"},
          {id: 2, title: "상담문의", link: "/community/customer"}
    ]
  },
]