import { useCallback, useEffect, useRef, useState } from "react";

const useCustomSelect = (selectedName, selectedVal) => {
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState(selectedName);
  const [value, setValue] = useState(selectedVal);
  const selectRef = useRef(null);

  const handleSelect = (selectedName, selectedVal) => {
    setName(selectedName);
    setValue(selectedVal);
  }
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const toggle = useCallback(() => {
    setIsOpen(prev => (!prev));
  },[isOpen,setIsOpen])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return {
    isOpen,
    setIsOpen,
    selectRef,
    name,
    setName,
    value,
    setValue,
    handleSelect,
    handleClickOutside,
    toggle
  }
}

export default useCustomSelect;