export const thisYear = new Date().getFullYear();

export const getYears = (startYear, thisYear) => {
  const years = [];
  for(let year = thisYear; year > startYear; year--){
    years.push(year);
  }
  return years;
}

export const getMonths = () => {
  return Array.from({length: 12}, (_, index) => index + 1);
}

export const getDays = (year, month) => {
  const febDays = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
  const monthsWith30Days = [4, 6, 9, 11];

  if (month === 2) {
    return Array.from({ length: febDays }, (_, index) => index + 1);
  } else if (monthsWith30Days.includes(month)) {
    return Array.from({ length: 30 }, (_, index) => index + 1);
  } else {
    return Array.from({ length: 31 }, (_, index) => index + 1);
  }
};

export const dateDifference = (date1, date2) => {
  const assignDate = new Date(date1);
  const getDate = new Date(date2);
  return assignDate - getDate;
}

export const yearDifference = (date) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const daysDifference = Math.floor(date / millisecondsPerDay);
  const yearsDifference = Math.floor(daysDifference / 365);
  return yearsDifference;
}