import { Link, useLocation } from "react-router-dom";
import "./header.scss";
import { useEffect, useState } from "react";
import { NavData } from "./NavData";
import RightNav from "./RightNav";
import uuid from 'react-uuid'

const Header = ({ noWheel, notFound }) => {
  const { pathname } = useLocation();
  const [isFixed, setIsFixed] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [isActiveNav, setIsActiveNav] = useState(false);
  useEffect(() => {
    if (pathname === "/" || notFound === true) {
      setIsFixed(false);
    } else {
      setIsFixed(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isActiveNav) {
      setDropdownActive(false);
    }
  }, [isActiveNav]);

  return (
    <>
      {pathname === "/" || notFound ? (
        <header className={noWheel ? "" : "color"}>
          <div className="inner">
            <Link to="/" className={noWheel ? "logo" : "logo color"}>
              <img
                src={
                  noWheel
                    ? "/images/icons/ico-logo.png"
                    : "/images/icons/ico-logo-color.png"
                }
                alt="로고"
              />
            </Link>
            <ul className="gnb">
              {NavData.map((it, idx) => (
                <li key={uuid()}>
                  <Link to={it.link} className={noWheel ? "" : "color"}>
                    {it.title}
                  </Link>
                </li>
              ))}
              {isActiveNav ? (
                <button
                  className="btn_hamburger active"
                  onClick={() => {
                    setIsActiveNav((prev) => !prev);
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              ) : (
                <button
                  className={noWheel ? "btn_hamburger" : "btn_hamburger color"}
                  onClick={() => {
                    setIsActiveNav((prev) => !prev);
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              )}
              <RightNav
                isActiveNav={isActiveNav}
                setIsActiveNav={setIsActiveNav}
                dropdownActive={dropdownActive}
                setDropdownActive={setDropdownActive}
              />
            </ul>
          </div>
        </header>
      ) : (
        <header className="color">
          <div className="inner">
            <Link to="/" className="logo color">
              <img src="/images/icons/ico-logo-color.png" alt="로고" />
            </Link>
            <ul className="gnb">
              {NavData.map((it, idx) => (
                <li key={uuid()}>
                  <Link to={it.link} className="color">
                    {it.title}
                  </Link>
                </li>
              ))}
              {isActiveNav ? (
                <button
                  className="btn_hamburger active"
                  onClick={() => {
                    setIsActiveNav((prev) => !prev);
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              ) : (
                <button
                  className="btn_hamburger color"
                  onClick={() => {
                    setIsActiveNav((prev) => !prev);
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              )}
              <RightNav
                isActiveNav={isActiveNav}
                setIsActiveNav={setIsActiveNav}
                dropdownActive={dropdownActive}
                setDropdownActive={setDropdownActive}
              />
            </ul>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
