import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HiArrowRight } from "react-icons/hi";
import ImageSlider from "./include/ImageSlider";
import SelectBox from "../community/component/SelectBox";
import { requestOption } from "../community/selectOption";
import useCustomSelect from "../community/hook/useCustomSelect";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ConfirmModal from "../community/ConfirmModal";

const Main = () => {
  const slideRef = useRef();
  const [currentIdx, setCurrentIdx] = useState(0);
  const inputRef = useRef([]);
  const navigate = useNavigate();

  const inputValue = {
    qnaName: "",
    qnaPhone: "",
    qnaEmail: "",
    qnaGubunCode: "",
    question: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [values, setValues] = useState(inputValue);
  const { qnaName, qnaPhone, qnaEmail, question } = values;
  const [loading, setLoading] = useState(false);
  const [privacyChk, setPrivacyChk] = useState(false);
  const requestSelect = useCustomSelect("문의 분류", "");
  const emailForm =
    /^[A-Za-z0-9-._]*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,5}$/i;
  const telWithCommas = (str) => {
/*    let localNum = str.slice(0, 2);
    if (localNum === "02" && str.length === 10) {
      return str.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    } else {
      return str.replace(/(\d{2,3})(\d{3,4})(\d{4})/, "$1-$2-$3");
    }*/
    return str.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  };
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();

      if (loading) {
        return false;
      }
      for (let i = 0; i < inputRef.current.length; i++) {
        if (i === 3 && requestSelect.value === "") {
          let selectTag = document.getElementById("selectTag");
          alert("문의 분류를 선택해 주세요.");
          selectTag.focus();
          return false;
        }
        if (
          inputRef.current[i].value === "" ||
          !inputRef.current[i].value.trim()
        ) {
          alert(inputRef.current[i].title + "을(를) 입력해주세요.");
          inputRef.current[i].focus();
          return false;
        }
      }
      if(qnaPhone.split("-").join("").length < 10){
        alert('연락처를 확인해주세요.');
        inputRef.current[1].focus();
        return false;
      }
      if (!emailForm.test(qnaEmail)) {
        alert('이메일 양식을 확인해주세요.(특수문자는 ".-_"만 허용됩니다.)');
        inputRef.current[2].focus();
        return false;
      }
      if (privacyChk === false) {
        alert("개인정보 수집 및 이용을 동의해주세요");
        return false;
      }
      try {
        await axios
          .post(
            `https://old.wmtax337.com/usr/community/customerWriteAjax`,
            {
              qnaName: qnaName,
              qnaPhone: qnaPhone.split("-").join(""),
              qnaEmail: qnaEmail,
              qnaGubunCode: requestSelect.value,
              question: question,
            },
            {
              headers: {
                "Content-Type": `application/x-www-form-urlencoded`,
              },
            }
          )
          .then((res) => {
            setValues({ ...inputValue });
            setIsOpen(true);
          });
      } catch (error) {
        console.log('error',error);
        alert("오류가 발생했습니다. 관리자에게 연락 부탁드립니다.");
      } finally {
        setLoading(false);
        setPrivacyChk(false);
        requestSelect.setName("문의 분류");
        requestSelect.setValue("");
      }
    },
    [values, privacyChk, requestSelect.value]
  );

  // useEffect(() => {
  //   if (confirm === true) {
  //     setIsOpen(false);
  //     setPrivacyChk(false);
  //     requestSelect.setName("문의 분류");
  //     requestSelect.setValue("");
  //   }
  // }, [confirm]);

  return (
    <>
      <section className="main01">
        <ImageSlider setCurrentIdx={setCurrentIdx} slideRef={slideRef} />
        <div className="main01_wrap">
          <h2 className="main01_sub_title">THE BEST CUSTOMER SERVICE</h2>
          <h2 className="main01_title">
            HAN KYUNG <span className="ls_kr">세무법인</span>
          </h2>
          <p className="main01_describe">
            <span>
              한경세무법인은 늘 고객과의 유기적인 소통을 통해 차별화되고&nbsp;
            </span>
            <span>능동적인 맞춤형 관리 서비스를 제공합니다.</span>
          </p>
          <button
            className="btn_service_rq"
            onClick={() => navigate("/community/customer")}
          >
            서비스 문의하기
            <HiArrowRight className="btn_arrow" />
          </button>
          <ul className="dots_area">
            <li
              className={currentIdx === 0 ? "dots active" : "dots"}
              onClick={() => {
                slideRef.current?.slickGoTo(0, false);
              }}
            ></li>
            <li
              className={currentIdx === 1 ? "dots active" : "dots"}
              onClick={() => {
                slideRef.current?.slickGoTo(1, false);
              }}
            ></li>
            <li
              className={currentIdx === 2 ? "dots active" : "dots"}
              onClick={() => {
                slideRef.current?.slickGoTo(2, false);
              }}
            ></li>
          </ul>
        </div>
        <div className="scroll_text">
          <span>Scroll Down</span>
        </div>
      </section>
      <section className="tax_check">
        <p>
          <span>사전증여를 계획하고 있다면&nbsp;</span>
          <span>
            예상세금 미리미리&nbsp;<span className="mark">Check!</span>
          </span>
          <Link to="/community/taxCalculator" state={{ isTax: false }}>
            <button className="btn_round">세금 계산하기</button>
          </Link>
        </p>
      </section>
      <section className="main02">
        <div className="main02_wrapper">
          <h2>
            <span className="sub_title">OUR SERVICES</span>
            <span className="main_title">
              세무를 향한 자문, 절세가이드
              <br />그 탁월한 경험을 만나다
            </span>
          </h2>
          <ul className="main02_contents">
            <li className="content_item">
              <div className="describe">
                <h3>기장서비스</h3>
                <p>
                  고객과의 소통을 통해 차별화된 능동적인 기장서비스로 확실한
                  절세를 만들어 드리겠습니다.
                </p>
              </div>
            </li>
            <li className="content_item">
              <div className="describe">
                <h3>상속세서비스</h3>
                <p>
                  상속재산 조회 및 분석까지 One-Stop으로
                  <br /> 억울하게 내는 세금이 없도록 해 드리겠습니다.
                </p>
              </div>
            </li>
            <li className="content_item">
              <div className="describe">
                <h3>증여세서비스</h3>
                <p>
                  고객의 니즈를 반영해 증여를 통한 종합적인
                  <br /> 절세 솔루션을 제시해 드리겠습니다.
                </p>
              </div>
            </li>
            <li className="content_item">
              <div className="describe">
                <h3>양도소득세서비스</h3>
                <p>
                  선 세금 컨설팅 후 양도를 통해
                  <br /> 절세를 위한 대책을 마련해 드립니다.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section className="main03">
        <div className="main03_wrap">
          <div className="main03_content">
            <h2 className="main03_title">
              주식투자를 하고 있다면
              <br />
              대주주가 아닌지 미리미리 <span className="mark">Check!</span>
            </h2>
            <p>
              2018년 하반기 많은 분들이 대주주양도소득세 기한후신고안내문을
              받았습니다. <br />
              이는 2016.4.1부터 낮아진 기준에 따라 대주주에 해당하는데도
              불구하고 <br />
              대주주 양도소득세를 신고 납부하지 않은 분들을 대상으로 실시된
              조사였습니다.
            </p>
          </div>
          <div className="calculator">
            <div className="calculator_title">
              <h3>세금 계산기</h3>
              <h4>
                계산할 세금 항목을 <br />
                아래에서 선택해 주세요.
              </h4>
            </div>
            <ul className="calculator_list">
              <li>
                <Link to="/community/taxCalculator" state={{ isTax: true }}>
                  <button className="btn_calculator_w">
                    <div>양도소득세 계산하기</div>
                  </button>
                </Link>
              </li>
              <li>
                <Link to="/community/taxCalculator" state={{ isTax: false }}>
                  <button className="btn_calculator_b">
                    <div>증여세 계산하기</div>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="main04">
        <div className="main04_wrap">
          <div className="contact_wrap">
            <h3>Contact us</h3>
            <div className="division"></div>
            <p>상담전화</p>
            <span className="phone dp-block">02)6925-3438</span>
            <span className="time">평일 09:00 - 18:00</span>
          </div>
          <form onSubmit={onSubmit}>
            <div className="input_flex">
              <div className="input_wrap">
                <label>Name</label>
                <input
                  type="text"
                  value={qnaName}
                  name="qnaName"
                  title="이름"
                  onChange={handleChange}
                  placeholder="이름을 입력해주세요."
                  ref={(el) => (inputRef.current[0] = el)}
                />
              </div>
              <div className="input_wrap">
                <label>Tel</label>
                <input
                  type="text"
                  value={telWithCommas(qnaPhone.replace(/[^0-9]/g, ""))}
                  name="qnaPhone"
                  title="연락처"
                  onChange={handleChange}
                  placeholder="연락처를 입력해주세요."
                  ref={(el) => (inputRef.current[1] = el)}
                  maxLength="13"
                />
              </div>
            </div>
            <div className="input_flex">
              <div className="input_wrap">
                <label>E-mail</label>
                <input
                  type="text"
                  value={qnaEmail}
                  name="qnaEmail"
                  title="이메일"
                  onChange={handleChange}
                  placeholder="이메일을 입력해주세요."
                  ref={(el) => (inputRef.current[2] = el)}
                />
              </div>
              <div className="input_wrap">
                <label>문의 분류</label>
                <SelectBox
                  select={requestSelect}
                  options={requestOption}
                  name={"qnaGubunCode"}
                  style={"select dropdown"}
                />
              </div>
            </div>
            <div className="input_flex">
              <div className="input_wrap">
                <label>Message</label>
                <textarea
                  name="question"
                  value={question}
                  title="문의 내용"
                  onChange={handleChange}
                  placeholder="문의 내용을 입력해주세요."
                  ref={(el) => (inputRef.current[3] = el)}
                />
                <div className="dp_flex">
                  <label className="agree">
                    <input
                      type="checkbox"
                      name="privacyChk"
                      value={privacyChk}
                      title="개인정보 수집 이용"
                      onChange={() => setPrivacyChk(!privacyChk)}
                      className="chk_agree"
                      checked={privacyChk}
                    />
                    <span>개인정보 수집 이용에 관한 동의</span>
                  </label>
                  <a
                    className="detail"
                    onClick={() => {
                      window.open(
                        "/popup/TermPersonal",
                        "window",
                        "width=500, height=370, location=no, status=no, scrollbars=yes"
                      );
                    }}
                  >
                    [ 자세히 보기 ]
                  </a>
                </div>
              </div>
            </div>
            <button>상담 신청</button>
          </form>
        </div>
      </section>
      {isOpen && (
        <ConfirmModal
          setIsOpen={setIsOpen}
          setConfirm={setConfirm}
          confirm={confirm}
        />
      )}
    </>
  );
};

export default Main;
