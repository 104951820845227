import { useState } from "react";
import TaxForm from "./TaxForm";
import GiftTaxForm from "./GiftTaxForm";
import { useLocation } from "react-router-dom";

const TaxCalculator = () => {
  const { state } = useLocation();
  const [ isTax, setIsTax ] = useState(state ? state?.isTax : true);

  return (
      <>
        <div className="calculator_area">
          <div className="tax_btn_wrap">
            <button className={isTax === true ? 'active' : ''} onClick={() => {setIsTax(true)}}>양도소득세</button>
            <button className={isTax === false ? 'active' : ''} onClick={() => {setIsTax(false)}}>증여세</button>
          </div>
        </div>
        {isTax ? <TaxForm/> : <GiftTaxForm/>}
      </>
  )
}
export default TaxCalculator;