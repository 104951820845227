import "./footer.scss";

const Footer = ({ noWheel, bgActive }) => {
  const onTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={bgActive ? "Footer active" : "Footer"}>
      <h1>
        <img src="/images/icons/ico-logo-color.png" alt="logo" />
      </h1>
      <div>
        <p className="address">
          서울특별시 송파구 올림픽로 342(방이동),&nbsp;
          <br />
          아울타워 7층
        </p>
        <p className="contact">
          <div className="tel_wrap">
            <span className="tag">TEL :</span>
            <div>
              <span>02-6925-3438 / 3498</span>
              <span className="second_tel">02-6952-3471 / 3472 / 3476</span>
            </div>
          </div>
          <span><span className="tag">FAX :</span>02-6442-6137</span>
          <span>
            <span className="tag">E-MAIL :</span>
            moson77@wmtax337.com
          </span>
        </p>
        <p className="copyright">
          COPYRIGHT©2019 HAN KYUNG tax consulting CO., LTD ALL RIGHTS RESERVED.
        </p>
      </div>
      <div className="top" onClick={() => onTop()}>
        <div className="icon_top">TOP</div>
      </div>
    </footer>
  );
};

export default Footer;
