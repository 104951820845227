import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useRef, useState } from "react";
import ConfirmModal from "./ConfirmModal";
import { domainOption, requestOption } from "./selectOption";
import useCustomSelect from "./hook/useCustomSelect";
import SelectBox from "./component/SelectBox";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";

const Community = () => {
  const initialValue = {
    qnaName: '',
    qnaPhone:'',
    emailAccount: '',
    emailDomain: '',
    qnaGubunCode: '',
    title: '',
    contents: ''
  }
  const emailDomainSelect = useCustomSelect('직접입력','');
  const requestSelect = useCustomSelect('문의 분류','');
  const useRefs = useRef([]);
  const emailForm = /^[a-zA-Z]+\.[a-zA-Z]+$/;
  const emailId = /^[A-Za-z0-9-._]*$/g;
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [value, setValue] = useState({...initialValue})
  const {
    qnaName,
    qnaPhone,
    emailAccount,
    emailDomain,
    title,
    contents
  } = value
  const [agree, setAgree] = useState("true");
  const telWithCommas = (str) => {
    // let localNum = str.slice(0,2)
/*    if(localNum === '02' && str.length === 10){
      return str.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
      return str.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3');
    }*/
    return str.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  };
  const handleOnChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  }
  const handleAgreeOnChange = (e) => {
    setAgree(e.target.value);
  }
  const onSubmit = async (e) => {
    // 문의버튼 클릭 시 튕김 방지
    const footer = document.getElementsByClassName("Footer");
    footer[0].focus();
    e.preventDefault();
    if (loading) {
      return false;
    }
    try {
      for (let i = 0; i < useRefs.current.length; i++) {
        if(i === 4 && requestSelect.value === ''){
          let selectTag = document.getElementById("selectTag");
          alert('문의 분류를 선택해 주세요.')
          selectTag.focus();
          return false;
        }
        if (useRefs.current[i].value === '' || !useRefs.current[i].value.trim()) {
          alert(useRefs.current[i].title);
          useRefs.current[i].focus();
          useRefs.current[i].scrollIntoView({block: 'center'})
          return false;
        }
      }
      if(qnaPhone.split("-").join("").length < 10){
        alert('연락처를 확인해주세요.');
        useRefs.current[1].focus();
        return false;
      }
      if (!emailId.test(emailAccount)) {
        alert('이메일 아이디를 확인해주세요. (특수문자는 ".-_"만 허용됩니다.)');
        useRefs.current[2].focus();
        return false;
      }
      if(!emailForm.test(emailDomain)){
        alert('이메일 도메인을 확인해주세요');
        useRefs.current[3].focus();
        return false;
      }
      if (agree === "false" || !agree) {
        alert('개인정보 수집 및 이용을 동의해 주세요.');
        return false;
      }
      let submitVal = {
        qnaName: value.qnaName,
        qnaPhone: value.qnaPhone.split('-').join(''),
        qnaEmail: `${value.emailAccount}@${value.emailDomain}`,
        qnaGubunCode: requestSelect.value,
        question: `${title}\r\n${contents}`
      }
      const response = await axios.post(
          'https://old.wmtax337.com/usr/community/customerWriteAjax',
          submitVal,
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded'}}
      );
      if(response.status === 200){
        setIsOpen(true);
      }
    } catch(error) {
      alert('서버에러가 발생했습니다. 관리자에게 문의바랍니다.')
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setValue({...value,emailDomain:emailDomainSelect.value})
  },[emailDomainSelect.value])
  useEffect(() => {
    if (!isOpen && confirm === true) {
      setValue({...initialValue});
      emailDomainSelect.setName('직접입력');
      emailDomainSelect.setValue('');
      requestSelect.setName('문의 분류');
      requestSelect.setValue('');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  },[isOpen,confirm])
  return (
      <>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="table">
            <div className="tr">
              <div className="th">
                이름
              </div>
              <div className="td">
                <input className="w230 wp100" name="qnaName" title="이름을 입력해 주세요." value={qnaName} onChange={(e) => {handleOnChange(e)}} ref={(el) => (useRefs.current[0] = el)}/>
              </div>
            </div>
            <div className="tr">
              <div className="th">
                연락처
              </div>
              <div className="td">
                <input
                    className="w230 wp100"
                    name="qnaPhone"
                    title="연락처를 입력해 주세요."
                    onChange={(e) => {handleOnChange(e)}}
                    placeholder="숫자만 입력해주세요" ref={(el) => (useRefs.current[1] = el)}
                    value={telWithCommas(qnaPhone.toString().replace(/[^0-9]/g, ''))}
                    maxLength="13"
                />
              </div>
            </div>
            <div className="tr email">
              <div className="th email">
                이메일
              </div>
              <div className="td email">
                <input className="w140 email" value={emailAccount} name="emailAccount" title="이메일 아이디를 입력해 주세요." onChange={(e) => {handleOnChange(e)}} ref={(el) => (useRefs.current[2] = el)}/>
                @
                <input className="w140 email"
                       title="이메일 도메인을 입력해 주세요."
                       value={emailDomainSelect.name==='직접입력' ? emailDomain : emailDomainSelect.value}
                       name="emailDomain"
                       ref={(el) => (useRefs.current[3] = el)}
                       onChange={(e) => {handleOnChange(e)}}
                       readOnly={emailDomainSelect.name !== '직접입력' && true}
                />
                <SelectBox select={emailDomainSelect} options={domainOption} name={"emailDomain"} style="w140 email"/>
              </div>
            </div>
            <div className="tr">
              <div className="th">
                문의 분류
              </div>
              <div className="td">
                <SelectBox select={requestSelect} options={requestOption} name={"qnaGubunCode"} style="w230 wp100" />
              </div>
            </div>
            <div className="tr">
              <div className="th">
                제목
              </div>
              <div className="td">
                <input className="wp100" value={title} name="title" title="제목을 입력해 주세요." onChange={(e) => {handleOnChange(e)}} ref={(el) => (useRefs.current[4] = el)}/>
              </div>
            </div>
            <div className="tr h_auto">
              <div className="th h_auto">
                내용
              </div>
              <div className="td">
                <textarea className="wp100" placeholder="문의 내용을 입력해주세요" name="contents" title="내용을 입력해 주세요." value={contents} onChange={(e) => {handleOnChange(e)}}  ref={(el) => (useRefs.current[5] = el)}/>
              </div>
            </div>
          </div>
          <div className="agreement">
            <h3>개인정보 수집 · 이용 관련 동의서</h3>
            <div className="agreement_content">
              <p>(한경세무법인(이하 ‘회사’)은 고객의 개인정보를 중요시 하며, 이에 고객문의와 관련하여 개인정보 수집·이용 관련 동의를 다음과 같이 받고자 합니다.</p>
              <p>개인(신용)정보 수집·이용 관련 동의</p>
              <p>- 수집/이용 항목 : 고객명, 휴대폰 번호, 이메일 주소</p>
              <p>- 수집/이용 목적 : 고객문의처리</p>
              <p>- 개인정보의 수집·이용 관련 동의일로부터 1년까지 활용 후, 파기됩니다.</p>
              <p>본인은 위와 같이 개인정보를 수집·이용하는데 대한 동의를 거부할 수 있습니다. 이에 대한 동의가 없을 경우 고객문의 접수가 불가함을 알려드립니다.</p>
            </div>
          </div>
          <div className="btn_chk">
            <label className="check_wrap">
              <input type="radio" className="check_box" name="agree" value="true" onChange={(e) => {handleAgreeOnChange(e)}} checked={agree === "true"}/>
              <span className="checkmark"></span>
              <span className="agree">동의</span>
            </label>
            <label className="check_wrap">
              <input type="radio" className="check_box" name="agree" value="false" onChange={(e) => {handleAgreeOnChange(e)}} checked={agree === "false"}/>
              <span className="checkmark"></span>
              <span className="agree">동의안함</span>
            </label>
          </div>
          <div className="confirm_btn_area">
            <Link className="white" to="/community/taxCalculator">
              <button type="button">이전</button>
            </Link>
            <button className="blue" type="button" onClick={(e) => {onSubmit(e)}}>문의</button>
          </div>
        </form>
        {isOpen && <ConfirmModal setIsOpen={setIsOpen} setConfirm={setConfirm} confirm={confirm}/>}
      </>
  )
}
export default Community;