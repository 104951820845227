export const domainOption = [
  {key: 0, name: '직접입력', value:''},
  {key: 1, name: 'naver.com', value:'naver.com'},
  {key: 2, name: 'gmail.com', value:'gmail.com'},
  {key: 3, name: 'hanmail.net',value:'hanmail.net'}
]
export const requestOption = [
  {key: 0, name: '기장', value:'QG01'},
  {key: 1, name: '상속세', value:'QG02'},
  {key: 2, name: '증여세', value:'QG03'},
  {key: 3, name: '양도소득세',value:'QG04'},
  {key: 4, name: '자금출처',value:'QG05'},
]

export const asset01 = [
  {key: 0, name: '사업용', value:'business'},
  {key: 1, name: '비사업용', value:'nonBusiness'}
]
export const asset02 = [
  {key: 0, name: '고가1주택비과세', value:'highOneHouse'},
  {key: 1, name: '1주택과세', value:'oneHouse'},
  {key: 2, name: '다주택일반과세', value:'multiHouse'},
  {key: 3, name: '2주택중과세', value:'twoHouse'},
  {key: 4, name: '3주택중과세', value:'threeHouse'},
]
export const asset03 = [
  {key: 0, name: '일반', value:'normal'}
]
export const relationship = [
  {key: 'spouse', name: '배우자', value:'spouse'},
  {key: 'sonAdult', name: '자(성인)', value:'sonAdult'},
  {key: 'sonKid', name: '자(미성년자)', value:'sonKid'},
  {key: 'grandsonAdult', name: '손자(성인)', value:'grandsonAdult'},
  {key: 'grandsonKid', name: '손자(미성년자)', value:'grandsonKid'},
  {key: 'father', name: '부', value:'father'},
  {key: 'mother', name: '모', value:'mother'},
  {key: 'daughterInLaw', name: '며느리', value:'daughterInLaw'},
  {key: 'sonInLaw', name: '사위', value:'sonInLaw'},
  {key: 'relative', name: '기타친족', value:'relative'}
]
