import { useEffect, useState } from "react";
import useCustomSelect from "./hook/useCustomSelect";
import useDate from "./hook/useDate";
import { dateDifference, thisYear, yearDifference } from "./date";
import SelectBox from "./component/SelectBox";
import * as calTax from "./calculate";

const TaxForm = () => {
  const defaultValue = {
    ownTerm: 0,
    giveCost: '',
    getCost: '',
    necessityCost: '',
    giveProfit: 0,
    giveTaxProfit: 0,
    longTermRefund: 0,
    totalGiveProfit: 0,
    giveTaxStandard: 0,
    giveTariff: 0,
    giveTariffCost: 0,
    localTax: 0,
    finalTax: 0
  }
  const assignDate = useDate();
  const getDate = useDate();
  const assignYear = useCustomSelect(assignDate.year, assignDate.year);
  const assignMonth = useCustomSelect(assignDate.month, assignDate.month);
  const assignDay = useCustomSelect(assignDate.day, assignDate.day);
  const getYear = useCustomSelect(getDate.year, getDate.year);
  const getMonth = useCustomSelect(getDate.month, getDate.month);
  const getDay = useCustomSelect(getDate.day, getDate.day);
  const [value, setValue] = useState({...defaultValue});
  const [ownYear, setOwnYear] = useState(0);
  // const detailGubun = useCustomSelect(asset01[0].name, asset01[0].value);
/*  const [assetGubun, setAssetGubun] = useState('land');
  const [taxGubun, setTaxGubun] = useState(asset01);*/

  const reset = (e) => {
    e.preventDefault();
/*    setAssetGubun('land')
    detailGubun.handleSelect(asset01[0].name, asset01[0].value)*/
    setValue({...defaultValue});
    assignYear.handleSelect(thisYear, thisYear)
    assignMonth.handleSelect(1, 1)
    assignDay.handleSelect(1, 1)
    getYear.handleSelect(thisYear, thisYear)
    getMonth.handleSelect(1, 1)
    getDay.handleSelect(1, 1)
    window.scrollTo(0, 0)
  }
  const handleOnchange = (e) => {
    if(ownYear < 0){
      alert('양도일자와 취득일자를 확인해주세요.');
      return false;
    } else {
      let onlyNumVal = e.target.value;
      onlyNumVal = onlyNumVal.toString().replace(/[^0-9]/g, '');
      setValue({...value, [e.target.name] : onlyNumVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
    }
  }
  /*  const handleRadioOnChange = (e) => {
    let asset = e.target.value;
    setAssetGubun(asset);
    if (asset === 'land') {
      setTaxGubun(asset01)
      detailGubun.handleSelect(asset01[0].name, asset01[0].value)
    } else if (asset === 'house') {
      setTaxGubun(asset02)
      detailGubun.handleSelect(asset02[0].name, asset02[0].value)
    } else {
      setTaxGubun(asset03);
      detailGubun.handleSelect(asset03[0].name, asset03[0].value)
    }
  };*/
  const calculateTax = () => {
    if(ownYear >=0) {
      let ltr = calTax.longTermRefundRate(value.ownTerm);
      let giveProfit = calTax.calGiveProfit(value.giveCost,value.getCost,value.necessityCost);
      let longTermRefund = calTax.calLongTermRefund(giveProfit,ltr);
      let totalGiveProfit = calTax.calTotalGiveProfit(giveProfit,longTermRefund);
      let giveTaxStandard = calTax.calGiveTaxStandard(totalGiveProfit);
      let giveTariff = calTax.calGiveTariff(giveTaxStandard);
      let { giveTariffCost, localTax, finalTax } = calTax.calTotalTax(giveTaxStandard,giveTariff);
      setValue({
        ...value,
        giveProfit:giveProfit,
        longTermRefund:longTermRefund,
        totalGiveProfit:totalGiveProfit,
        giveTaxStandard:giveTaxStandard,
        giveTariff:giveTariff,
        giveTariffCost:giveTariffCost,
        localTax:localTax,
        finalTax:finalTax
      })
    }
  }
  useEffect(() => {
    assignDay.handleSelect(1, 1);
  }, [assignDate.month])
  useEffect(() => {
    getDay.handleSelect(1, 1);
  }, [getDate.month])
  useEffect(() => {
    let date1 = String(assignYear.value).concat('-',String(assignMonth.value).padStart(2,'0'),'-',String(assignDay.value).padStart(2,'0'));
    let date2 = String(getYear.value).concat('-',String(getMonth.value).padStart(2,'0'),'-',String(getDay.value).padStart(2,'0'));
    const result = dateDifference(date1,date2);
    setOwnYear(result);
    if(result < 0){
      alert("'양도일자'와 '취득일자'를 확인해주세요. \n'양도일자'는 '취득일자' 이전으로 입력 불가합니다");
      setValue({
        ...value,
        giveProfit:0,
        longTermRefund:0,
        totalGiveProfit:0,
        giveTaxStandard:0,
        giveTariff:0,
        giveTariffCost:0,
        localTax:0,
        finalTax:0
      })
    } else if(result >= 0){
      setValue({...value,ownTerm: yearDifference(result)});
    }
  },[assignDate.year,assignDate.month,assignDate.day,getDate.year,getDate.month,getDate.day])

  useEffect(() => {
    calculateTax();
  },[value.ownTerm,value.getCost,value.giveCost,value.necessityCost,assignYear.value,assignMonth.value])

  return (
      <form>
        <p className="notice">* 보유기간이 2년이상으로 일반세율이 적용되는 경우만 계산합니다.</p>
        <div className="table mt0">
{/*          <div className="tr asset_gubun">
            <div className="th">
              양도자산종류
            </div>
            <div className="td asset_gubun">
              <div className="radioFlex flex">
                <label className="radioBox">
                  <input type="radio" className="check_box" name="asset_gubun" value="land"
                         onChange={handleRadioOnChange} checked={assetGubun === "land"}/>
                  <span className="checkmark"></span>
                  <span className="asset_label">토지</span>
                </label>
                <label className="radioBox">
                  <input type="radio" className="check_box" name="asset_gubun" value="house"
                         onChange={handleRadioOnChange} checked={assetGubun === "house"}/>
                  <span className="checkmark"></span>
                  <span className="asset_label">주택</span>
                </label>
                <label className="radioBox">
                  <input type="radio" className="check_box" name="asset_gubun" value="store"
                         onChange={handleRadioOnChange} checked={assetGubun === "store"}/>
                  <span className="checkmark"></span>
                  <span className="asset_label">일반상가</span>
                </label>
              </div>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              세부구분
            </div>
            <div className="td">
              <SelectBox select={detailGubun} options={taxGubun} name={"detailGubun"} style="w150 wp100"/>
            </div>
          </div>*/}
          <div className="tr h_auto">
            <div className="th h_auto">
              양도일자
            </div>
            <div className="td dp_block">
              <div className="date_wrap">
                <SelectBox select={assignYear} options={assignDate.yearArr.map((item) => {
                  return {key: item, name: item, value: Number(item) }
                })} name={"assignYear"} text={'년'} setDate={assignDate.setYear}/>
                <SelectBox select={assignMonth} options={assignDate.monthArr.map((item) => {
                  return {key: item, name: item, value: Number(item)}
                })} name={"assignMonth"} text={'월'} setDate={assignDate.setMonth}/>
                <SelectBox select={assignDay} options={assignDate.dayArr.map((item) => {
                  return {key: item, name: item, value: Number(item)}
                })} name={"assignDay"} text={'일'} setDate={assignDate.setDay}/>
              </div>
              <p>* 양도일 및 취득일은 계약일이 아닌 잔금청산일과 등기접수일 중 빠른 날입니다.</p>
            </div>
          </div>
          <div className="tr h_auto">
            <div className="th h_auto">
              취득일자
            </div>
            <div className="td dp_block">
              <div className="date_wrap">
                <SelectBox select={getYear} options={getDate.yearArr.map((item) => {
                  return {key: item, name: item, value: Number(item)}
                })} name={"getYear"} text={'년'} setDate={getDate.setYear}/>
                <SelectBox select={getMonth} options={getDate.monthArr.map((item) => {
                  return {key: item, name: item, value: Number(item)}
                })} name={"getMonth"} text={'월'} setDate={getDate.setMonth}/>
                <SelectBox select={getDay} options={getDate.dayArr.map((item) => {
                  return {key: item, name: item, value: Number(item)}
                })} name={"getDay"} text={'일'} setDate={getDate.setDay}/>
              </div>
              <p>* 양도일 및 취득일은 계약일이 아닌 잔금청산일과 등기접수일 중 빠른 날입니다.</p>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              보유기간
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.ownTerm} name="ownTerm" readOnly={true}/>
              <span className="unit">년</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              양도가액
            </div>
            <div className="td">
              <input className="w230 wp100" placeholder="ex)금액을 ,없이 입력해주세요" value={value.giveCost} name="giveCost"
                     onChange={(e) => handleOnchange(e)}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              취득가액
            </div>
            <div className="td">
              <input className="w230 wp100" placeholder="ex)금액을 ,없이 입력해주세요" value={value.getCost} name="getCost"
                     onChange={(e) => handleOnchange(e)}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr h_auto">
            <div className="th h_auto">
              필요경비
            </div>
            <div className="td dp_block">
              <div>
                <input className="necessity_cost" placeholder="ex)금액을 ,없이 입력해주세요" value={value.necessityCost} name="necessityCost"
                       onChange={(e) => handleOnchange(e)}/>
                <span className="unit ml9">원</span>
              </div>
              <p>* 부동산을 취득하면서 부담한 경비(취득세, 법무사수수료, 중개수수료, 기타 자본적지출로 볼 수 있는 비용 등) 를 입력합니다.</p>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              양도차익
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.giveProfit} name="giveProfit" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
{/*          <div className="tr">
            <div className="th">
              과세양도차익
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.giveTaxProfit} name="giveTaxProfit" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>*/}
          <div className="tr">
            <div className="th">
              장기보유특별공제
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.longTermRefund} name="longTermRefund" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              양도소득금액
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.totalGiveProfit} name="totalGiveProfit" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              양도소득기본공제
            </div>
            <div className="td">
              <input className="w230 wp100" value={"2,500,000"} name="totalGiveProfit" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              양도소득과세표준
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.giveTaxStandard} name="giveTaxStandard" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              양도소득세율
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.giveTariff} name="giveTariff" readOnly={true}/>
              <span className="unit">%</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              양도소득산출세액
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.giveTariffCost} name="giveTariffCost" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              지방소득세
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.localTax} name="localTax" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              납부할 세액
            </div>
            <div className="td">
              <input className="w230 wp100" value={value.finalTax} name="finalTax" readOnly={true}/>
              <span className="unit">원</span>
            </div>
          </div>
        </div>
{/*        {value.finalTax > 0 &&
            <div className="limit_date">{`예상신고 납부 기한은 ${value.limitDate.limitYear}년 ${value.limitDate.limitMonth}월 ${value.limitDate.limitDay}일 까지 입니다.`}</div>
        }*/}
        <div className="reset_btn_area">
          <button type="button" onClick={(e) => {
            reset(e)
          }}>초기화
          </button>
        </div>
      </form>
  )
}

export default TaxForm;