import React from "react";
import { useNavigate } from "react-router-dom";

const InheritanceTax = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="common">
        <div className="w1756">
          <h2>상속세서비스</h2>
          <p>상속, 가족의 집사가 되어 상속재산 분할 및 관리, 세금신고까지.</p>
          <figure>
            <img
              src="/images/sub_img_inheritance01.png"
              alt="상속세서비스"
              className="pc"
            />
            <img
              src="/images/sub_img_inheritance01_1300.png"
              alt="상속세서비스"
              className="w1300"
            />
            <img
              src="/images/sub_img_inheritance01_800.png"
              alt="상속세서비스"
              className="w800"
            />
            <img
              src="/images/sub_img_inheritance01_400.png"
              alt="상속세서비스"
              className="w400"
            />
          </figure>
        </div>
      </section>
      <section className="introduction">
        <div className="w1560">
          <div className="story">
            <h2>
              <span className="bold">상속재산 조회 및 분석</span>까지
              One-Stop으로&nbsp;
              <br className="br450" />
              <span className="bold">억울하게 내는 세금이 없도록 </span>해
              드리겠습니다.
            </h2>
            <p>
              상속세 신고는 정확한 계산만이 중요한 것이 아닙니다. 세밀한
              상속재산 조회 및 분석을 통해 <br /> 억울하게 내게 되는 세금이
              없도록 해야 합니다. 한경세무법인은 정확한 분석을 통해 절세할 수
              있도록 도와드리겠습니다.
            </p>
          </div>
          <ul className="flex">
            <li>
              <h3>상속재산 조회</h3>
              <p>
                정부의 안심상속서비스를 통한 <br className="pc" />
                피상속인의 상속재산 조회
              </p>
              <p>
                기타 상속재산 및 채무파알을 통한&nbsp;
                <br className="pc" />
                상속계획 수립
              </p>
            </li>
            <li>
              <h3>상속재산 분석</h3>
              <p>
                보험금, 금융재산, 부동산 등 <br className="pc" />
                상속재산 분석 및 분할
              </p>
              <p>
                피상속인 및 상속인 금융거래내역 &nbsp;
                <br className="line pc" />
                분석 및 파악
              </p>
            </li>
            <li>
              <h3>상속세 신고</h3>
              <p>
                2차 상속세는 물론, 상속 후 2차
                <br className="pc" />
                세금까지 고려한 전략 수립
              </p>
              <p>상속세 신고 및 세금 납부 계획</p>
            </li>
            <li>
              <h3>세무조사 대리</h3>
              <p>상속세 신고 후 세무조사대리</p>
            </li>
          </ul>
          <div className="cuBtn">
            <button onClick={() => navigate("/community/customer")}>
              상담 문의하기
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default InheritanceTax;
