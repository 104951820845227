import React from "react";
import { useNavigate } from "react-router-dom";

const CapitalGains = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="common gains">
        <div className="w1756">
          <h2>양도소득세서비스</h2>
          <p>
            부동산 양도는 물론 금융자산 양도까지, <br className="br400" /> 단순
            계산을 넘어선 사전 컨설팅으로.
          </p>
          <figure>
            <img
              src="/images/sub_img_capital01.png"
              alt="양도소득세서비스"
              className="pc"
            />
            <img
              src="/images/sub_img_capital01_1300.png"
              alt="양도소득세서비스"
              className="w1300"
            />
            <img
              src="/images/sub_img_capital01_800.png"
              alt="양도소득세서비스"
              className="w800"
            />
            <img
              src="/images/sub_img_capital01_400.png"
              alt="양도소득세서비스"
              className="w400"
            />
          </figure>
        </div>
      </section>
      <section className="measures">
        <div className="container">
          <div className="story">
            <h2>
              先세금컨설팅 後양도를 통해 <br />
              절세를 위한 대책을 마련해 드립니다.
            </h2>
            <p>
              양도소득세, 양도거래 완료 후 사후 상담은 절세를 위한 대책이
              없습니다.
              <br /> 양도세 과세대상 자산을 양도하는 경우에는 실질적인 계약 전
              세무상담을 통해 예상 부담세액 및 절세전략이 없는지 확인해야
              합니다.
            </p>
            <figure>
              <img src="/images/sub_img_capital02.png" alt="양도소득세서비스" />
            </figure>
          </div>
        </div>
      </section>
      <section className="strategy">
        <div className="container">
          <h2>
            양도 전 세법 검토를 통해 <br className="br360" /> 양도소득세
            전략을 세워드립니다.
          </h2>
          <ul className="flex">
            <li>
              <h3>부동산 양도</h3>
              <p>
                부동산 양도일이 속하는 달의 말일로부터 2개월 내 양도소득세 신고
                및 납부
              </p>
              <p>
                부동산을 양도하는 경우에는 특히 계약 전 세금문제에 대한 확인이
                필요합니다.
              </p>
              <p>先양도 後세금신고가 아닌 先세금컨설팅 後양도를 통해 절세</p>
            </li>
            <li>
              <h3>주식 양도</h3>
              <p>
                과세대상 주식을 양도하는 경우 양도일이 속하는 반기의 말일로부터
                2개월 내 양도소득세 신고 및 납부
              </p>
              <p>
                대주주라면 상장주식을 사고 팔면서 생긴 이익에 대해 양도소득세
                신고대상
              </p>
              <p>복잡한 금융거래내역도 정확하게 파악해 절세</p>
            </li>
          </ul>
          <div className="cuBtn">
            <button onClick={() => navigate("/community/customer")}>
              상담 문의하기
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CapitalGains;
