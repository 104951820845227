import React from "react";
import { useNavigate } from "react-router-dom";

const GiftTax = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="common giftTax">
        <div className="w1756">
          <h2>증여세서비스</h2>
          <p>
            증여, 간단한 신고대리부터 <br className="br400" />
            컨설팅을 통한 중장기 증여플랜까지.
          </p>
          <figure>
            <img
              src="/images/sub_img_gift01.png"
              alt="증여세서비스"
              className="pc"
            />
            <img
              src="/images/sub_img_gift01_1300.png"
              alt="증여세서비스"
              className="w1300"
            />
            <img
              src="/images/sub_img_gift01_800.png"
              alt="증여세서비스"
              className="w800"
            />
            <img
              src="/images/sub_img_gift01_400.png"
              alt="증여세서비스"
              className="w400"
            />
          </figure>
        </div>
      </section>
      <section className="introduction">
        <div className="container">
          <div className="story">
            <h2>
              <span className="bold">고객의 니즈</span>를 반영해
              <span className="bold"> 증여를 통한</span>
              <br className="br375" />
              <span className="bold">종합적인 절세 솔루션</span>을 제시해
              드리겠습니다.
            </h2>
            <p>
              한경세무법인은 단순한 세금 계산만을 반영한 컨설팅이 아닌 실제로
              심층적인 고객상담으로 <br className="br375" /> 고객의 니즈를
              반영하는 증여세는 물론 증여를 통한 종합적인 절세 솔루션을 제시해
              드리겠습니다.
            </p>
          </div>
          <figure className="giftImg">
            <img src="/images/sub_img_gift02.png" alt="" className="pc" />
            <img src="/images/sub_img_gift02_800.png" alt="" className="w800" />
            <img src="/images/sub_img_gift02_400.png" alt="" className="w400" />
          </figure>
          <div className="cuBtn">
            <button onClick={() => navigate("/community/customer")}>
              상담 문의하기
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default GiftTax;
