import { useEffect, useRef, useState } from "react";
import useCustomSelect from "./hook/useCustomSelect";
import { relationship } from "./selectOption";
import useDate from "./hook/useDate";
import { thisYear } from "./date";
import SelectBox from "./component/SelectBox";
import * as calTax from "./calculate";

const GiftTaxForm = () => {
  const defaultValue = {
    totalProperty: '',
    totalDebt: '',
    propertyTaxStandard: 0,
    propertyTaxCost: 0,
    generationSkipCost: 0,
    totalTax: 0,
    totalTaxRefund: 0,
    finalTax: 0
  }
  const relation = useCustomSelect(relationship[0].name,relationship[0].value);
  const giftDate = useDate();
  const giftYear = useCustomSelect(giftDate.year, giftDate.year);
  const giftMonth = useCustomSelect(giftDate.month, giftDate.month);
  const giftDay = useCustomSelect(giftDate.day, giftDate.day);
  const [value, setValue] = useState({...defaultValue});

  const reset = (e) => {
    e.preventDefault();
    setValue({...defaultValue});
    giftYear.handleSelect(thisYear,thisYear)
    giftMonth.handleSelect(1,1)
    giftDay.handleSelect(1,1)
    relation.handleSelect(relationship[0].name,relationship[0].value)
    window.scrollTo(0, 0)
  }

  const handleOnchange = (e) => {
    let onlyNumVal = e.target.value;
    onlyNumVal = onlyNumVal.toString().replace(/[^0-9]/g, '');
    setValue({...value, [e.target.name] : onlyNumVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
  }

  useEffect(() => {
    giftDay.handleSelect(1, 1);
  }, [giftDate.month])

  useEffect(() => {

    let propertyRefund = calTax.calPropertyRefund(relation.value);
    let propertyTaxStandard = calTax.calPropertyTaxStandard(value.totalProperty,value.totalDebt,propertyRefund);
    let propertyTaxRate = calTax.calPropertyTaxRate(propertyTaxStandard);
    let propertyTaxCost = calTax.calPropertyTaxCost(propertyTaxStandard);
    let generationSkipCost = calTax.calGenerationSkipCost(relation.value,propertyTaxCost);
    let totalTax = calTax.calTotalGiftTax(propertyTaxCost,generationSkipCost);
    let totalTaxRefund = calTax.calTotalTaxRefund(totalTax);
    let finalTax = calTax.calFinalGiftTax(totalTax,totalTaxRefund);


    setValue({
      ...value,
      propertyRefund:propertyRefund,
      propertyTaxStandard:propertyTaxStandard,
      propertyTaxRate:propertyTaxRate,
      propertyTaxCost:propertyTaxCost,
      generationSkipCost:generationSkipCost,
      totalTax:totalTax,
      totalTaxRefund: totalTaxRefund,
      finalTax: finalTax
    })
  },[relation.value,value.totalProperty,value.totalDebt])
  return(
      <form>
        <p className="notice">* 10년 내 기 사전증여가 없는 경우만 계산합니다.</p>
        <div className="table mt0">
          <div className="tr">
            <div className="th">
              증여일자
            </div>
            <div className="td date">
              <SelectBox select={giftYear}
                         options={giftDate.yearArr.map((item) => {
                           return {key: item, name: item, value: Number(item)}
                         })}
                         setDate={giftDate.setYear}
                         text={'년'}/>
              <SelectBox select={giftMonth}
                         options={giftDate.monthArr.map((item) => {
                           return {key: item, name: item, value: Number(item)}
                         })}
                         setDate={giftDate.setMonth}
                         text={'월'}/>
              <SelectBox select={giftDay}
                         options={giftDate.dayArr.map((item) => {
                           return {key: item, name: item, value: Number(item)}
                         })}
                         setDate={giftDate.setDay}
                         text={'일'}/>
            </div>
          </div>
          <div className="tr h_auto">
            <div className="th h_auto">
              증여자와의 관계
            </div>
            <div className="td dp_block">
              <div className="dp_flex gap10">
                <SelectBox select={relation} options={relationship} name={"relationship"} style="w230 wp100"/>
              </div>
              <p>* 만19세 미만인 경우 미성년자에 해당합니다.</p>
            </div>
          </div>
          <div className="tr h_auto">
            <div className="th h_auto">
              증여재산가액
            </div>
            <div className="td dp_block">
              <div>
                <input className="w230 price" placeholder="ex)금액을 ,없이 입력해주세요" name="totalProperty" value={value?.totalProperty} onChange={(e)=>{handleOnchange(e)}}/>
                <span className="unit ml9">원</span>
              </div>
              <p>
                * 시가가 원칙이며,시가가 불분명한 경우 기준시가로 평가합니다.
                (예 : 토지-개별공시지가)
              </p>
            </div>
          </div>
          <div className="tr h_auto">
            <div className="th h_auto">
              채무가액
            </div>
            <div className="td dp_block">
              <div>
                <input className="w230 price" placeholder="ex)금액을 ,없이 입력해주세요" name="totalDebt" value={value.totalDebt} onChange={(e)=>{handleOnchange(e)}}/>
                <span className="unit ml9">원</span>
              </div>
              <p>
                * 증여재산과 함께 채무를 부담하는 경우 입력합니다.
                (예 : 전세보증금, 담보대출가액 등)
              </p>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              증여공제액
            </div>
            <div className="td">
              <input className="w230 price" value={value.propertyRefund} name="propertyRefund" onChange={(e)=>{handleOnchange(e)}} readOnly/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              증여세과세표준
            </div>
            <div className="td">
              <input className="w230 price" value={value.propertyTaxStandard} name="propertyTaxStandard" onChange={(e)=>{handleOnchange(e)}} readOnly/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              증여세율
            </div>
            <div className="td">
              <input className="w230 price" value={value.propertyTaxRate} name="propertyTaxRate" onChange={(e)=>{handleOnchange(e)}} readOnly/>
              <span className="unit">%</span>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              증여세산출세액
            </div>
            <div className="td">
              <input className="w230 price" value={value.propertyTaxCost} name="propertyTaxCost" onChange={(e)=>{handleOnchange(e)}} readOnly/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr h_auto">
            <div className="th h_auto">
              세대생략가산액
            </div>
            <div className="td dp_block">
              <div>
                <input className="w230 price" value={value.generationSkipCost} name="generationSkipCost" onChange={(e)=>{handleOnchange(e)}} readOnly/>
                <span className="unit ml9">원</span>
              </div>
              <p>* 조부모에게 증여받은 경우 세대생략가산액으로 산출세액의 30%를 가산합니다.</p>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              산출세액계
            </div>
            <div className="td">
              <input className="w230 price" value={value.totalTax} name="totalTax" onChange={(e)=>{handleOnchange(e)}} readOnly/>
              <span className="unit">원</span>
            </div>
          </div>
          <div className="tr h_auto">
            <div className="th h_auto">
              신고세액공제
            </div>
            <div className="td dp_block">
              <div>
                <input className="w230 price" value={value.totalTaxRefund} name="totalTaxRefund" onChange={(e)=>{handleOnchange(e)}} readOnly/>
                <span className="unit ml9">원</span>
              </div>
              <p>* 증여세 신고납부기한까지 신고를 한다면 3%의 세액을 공제해 줍니다.</p>
            </div>
          </div>
          <div className="tr">
            <div className="th">
              납부할세액
            </div>
            <div className="td">
              <input className="w230 price" value={value.finalTax} name="finalTax" onChange={(e)=>{handleOnchange(e)}} readOnly/>
              <span className="unit">원</span>
            </div>
          </div>
        </div>
        <div className="reset_btn_area">
          <button type="button" onClick={(e) => {reset(e)}}>초기화</button>
        </div>
      </form>
  )

}

export default GiftTaxForm;