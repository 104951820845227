import React from "react";
import { useNavigate } from "react-router-dom";

const AccountBook = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="common">
        <div className="w1756">
          <h2>기장서비스</h2>
          <p>
            사업에만 전념할 수 있도록 <br className="br400" /> 세무업무의 든든한
            파트너가 되겠습니다.
          </p>
          <figure>
            <img
              src="/images/sub_img_account01.png"
              alt="기장서비스"
              className="pc"
            />
            <img
              src="/images/sub_img_account01_1300.png"
              alt="기장서비스"
              className="w1300"
            />
            <img
              src="/images/sub_img_account01_800.png"
              alt="기장서비스"
              className="w800"
            />
            <img
              src="/images/sub_img_account01_400.png"
              alt="기장서비스"
              className="w400"
            />
          </figure>
        </div>
      </section>
      <section className="introduction">
        <div className="container">
          <div className="story">
            <h2>
              <span className="bold"> 고객과의 소통</span>을 통해
              <span className="bold"> 차별화되고 능동적인 </span>
              <br className="br400" />
              <span className="bold"> 기장서비스로 확실한 절세</span>를 만들어
              드리겠습니다.
            </h2>
            <p>
              한경세무법인은 회계전문가가 고객은 물론 전담세무사와 유기적으로
              소통하면서 장부를 작성하고
              <br className="w800" /> 관리해 드립니다.
              <br className="pc" /> 고객이 제출하는 자료만에 의한 소극적인
              기장서비스가 아닌 <br className="w800" /> 차별화된 능동적인
              기장서비스로 확실한 절세를 통한 만족을 드리겠습니다.
            </p>
            <figure className="accountImg">
              <img
                src="/images/sub_img_account02.png"
                alt=""
                className="pcAccount"
              />
              <img
                src="/images/sub_img_account02_400.png"
                alt=""
                className="w400"
              />
            </figure>
          </div>
          <div className="workGrid">
            <div className="item">
              <h3>사업자등록 및 정정대행</h3>
              <p>신규 사업자의 사업자등록 대행</p>
              <p>기존 사업자의 사업자정정 대행</p>
            </div>
            <div className="item">
              <h3>신고대리업무</h3>
              <p className="p_pc">
                소규모 사업자 등 간편장부대상자의 부가가치세
                <br className="br600" />
                <span className="pc">및 소득세 신고대리</span>
              </p>
              <p className="p580">
                소규모 사업자 등 간편장부대상자의 <br className="br500" />
                <span className="pc">부가가치세 및 소득세 신고대리</span>
              </p>
            </div>
            <div className="item">
              <h3>세무기장대행</h3>
              <p className="p_pc">
                매월 급여신고 및 분기별/반기별 부가가치세 신고
              </p>
              <p className="p_pc">
                장부기장을 통한 매년 법인세 및 종합소득세 관리 및 조정
              </p>
              <p className="p580">
                매월 급여신고 및 분기별/반기별 <br className="br500" />
                <span>부가가치세 신고</span>
              </p>
              <p className="p580">
                장부기장을 통한 매년 법인세 및 <br className="br500" />
                <span>종합소득세 관리 및 조정</span>
              </p>
            </div>
            <div className="item">
              <h3>세무조사대리</h3>
              <p className="p580">
                세무조사 선정업체의 <br className="br500" />
                <span>세무조사대리</span>
              </p>
              <p className="p_pc">세무조사 선정업체의 세무조사대리</p>
            </div>
          </div>
          <div className="cuBtn">
            <button onClick={() => navigate("/community/customer")}>
              상담 문의하기
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountBook;
