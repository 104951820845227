import React, { useEffect } from 'react';
import './term.scss';

const TermPersonal = () => {
  useEffect(() => {
    document.querySelector('header').style.display = 'none';
    document.querySelector('footer').style.display = 'none';
  }, []);
  return (
    <section>
      <div className="popup_con">
        <p className="terms_title">개인정보 수집 · 이용 관련 동의서</p>
        <p className="terms_text">
          한경세무법인(이하 ‘회사’)은 고객의 개인정보를 중요시 하며, 이에 고객문의와 관련하여 개인정보 수집·이용 관련 동의를 다음과 같이 받고자 합니다.
        </p>
        <p className="terms_text">
          개인(신용)정보 수집·이용 관련 동의
        </p>
        <p className="terms_text mt5 bold">
          - 수집/이용 항목 : 고객명, 휴대폰 번호, 이메일 주소<br/>
          - 수집/이용 목적 : 고객문의처리<br/>
          - 개인정보의 수집·이용 관련 동의일로부터 1년까지 활용 후, 파기됩니다.
        </p>
        <p className="terms_text">
          본인은 위와 같이 개인정보를 수집·이용하는데 대한 동의를 거부할 수 있습니다.<br/>
          이에 대한 동의가 없을 경우 고객문의 접수가 불가함을 알려드립니다.
        </p>
      </div>
    </section>
  );
};

export default TermPersonal;
