import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ConfirmModal = ({ setIsOpen, setConfirm }) => {
  useEffect(() => {
    document.body.style.cssText = `
    position: fixed;
    top: -${window.scrollY}px;
    overflow-y: scroll;
    width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <>
      <section className="modalWrap">
        <div className="confirmModal" onClick={(e) => e.stopPropagation()}>
          <img
            className="modalClose"
            src="/images/icons/modal_close.png"
            onClick={() => {
              setConfirm(true);
              setIsOpen(false);
            }}
          />
          <h1 className="modalTitle">상담문의 접수 완료</h1>
          <div className="modalContent">
            <p>문의하신 내용은 담당자 확인 후,</p>
            <p>기입하신 연락처로 전달 드리겠습니다.</p>
            <p>감사합니다.</p>
          </div>
          <button
            className="confirmBtn"
            onClick={() => {
              setConfirm(true);
              setIsOpen(false);
            }}
            type="button"
          >
            확인
          </button>
        </div>
      </section>
    </>
  );
};

export default ConfirmModal;
