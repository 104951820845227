import { useEffect, useRef, useState } from "react";
import {getDays, getMonths, getYears, thisYear} from "../date";

const useDate = () => {
  const [year, setYear] = useState(thisYear);
  const [month, setMonth] = useState(1);
  const [day, setDay] = useState(1);
  const [yearArr, setYearArr] = useState(getYears(1984, thisYear));
  const [monthArr, setMonthArr] = useState(getMonths());
  const [dayArr,setDayArr] = useState(getDays(yearArr[0],monthArr[0]));
  const onChangeYearMonth = (year,month) => {
    let days = getDays(year,month);
    setDayArr([...days]);
  }
  useEffect(() => {
    onChangeYearMonth(year,month)
  },[year, month])

  return {
    year,
    setYear,
    month,
    setMonth,
    day,
    setDay,
    yearArr,
    monthArr,
    dayArr,
    setDayArr,
    onChangeYearMonth
  }
}
export default useDate;
