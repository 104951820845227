export const strToNum = (str='') => {
  let res = Number(str?.toString().split(',').join(''));
  return res;
}
export const NumToStr = (num=0) => {
  let res = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return res;
}
// 양도차익
export const calGiveProfit = (giveCost,getCost,necessityCost) => {
  giveCost = strToNum(giveCost);
  getCost = strToNum(getCost);
  necessityCost = strToNum(necessityCost);

  if(giveCost === null) giveCost = 0;
  if(getCost === null) getCost = 0;

  let res = giveCost-getCost-necessityCost;
  if(res<0) res = 0;
  return NumToStr(res);
}
// 장기보유특별공제율(세무구분에 따른 공제율 * 보유기간)
export const longTermRefundRate = (ownTerm) => {
  ownTerm = strToNum(ownTerm);
  let res = 0;
    if(ownTerm >= 3 && ownTerm < 4){
      res = 0.06;
    } else if (ownTerm >= 4 && ownTerm < 5){
      res = 0.08;
    } else if (ownTerm >= 5 && ownTerm < 6){
      res = 0.1;
    } else if(ownTerm >= 6 && ownTerm < 7){
      res = 0.12;
    } else if (ownTerm >= 7 && ownTerm < 8){
      res = 0.14;
    } else if(ownTerm >= 8 && ownTerm < 9){
      res = 0.16;
    } else if(ownTerm >= 9 && ownTerm < 10){
      res = 0.18;
    } else if(ownTerm >= 10 && ownTerm < 11){
      res = 0.20;
    } else if(ownTerm >= 11 && ownTerm < 12){
      res = 0.22;
    } else if(ownTerm >= 12 && ownTerm < 13){
      res = 0.24;
    } else if(ownTerm >= 13 && ownTerm < 14){
      res = 0.26;
    } else if(ownTerm >= 14 && ownTerm < 15){
      res = 0.28;
    } else if(ownTerm >= 15){
      res = 0.30;
    }
  return res;
}
// 장기보유특별공제
export const calLongTermRefund = (giveTaxProfit,longTermRefundRate) => {
  giveTaxProfit = strToNum(giveTaxProfit);
  longTermRefundRate = strToNum(longTermRefundRate);
  let res = giveTaxProfit * longTermRefundRate;
  res = Math.floor(res);

  if(res<0) res = 0;
    return NumToStr(res);
}
// 양도소득금액
export const calTotalGiveProfit = (giveTaxProfit,longTermRefund) => {
  giveTaxProfit = strToNum(giveTaxProfit);
  longTermRefund = strToNum(longTermRefund);

  let res = giveTaxProfit-longTermRefund;
  res = Math.floor(res);

  return NumToStr(res);
}
// 양도소득과세표준
export const calGiveTaxStandard = (totalGiveProfit) => {
  totalGiveProfit = strToNum(totalGiveProfit);
  let res = 0;
  res = totalGiveProfit - 2500000;
  if(res<0) res = 0;
  return NumToStr(res);
}
// 양도소득세율
export const calGiveTariff = (giveTaxStandard) => {
  giveTaxStandard = strToNum(giveTaxStandard);
  let res = 0;
    if(giveTaxStandard <= 14000000){
      res = 6;
  }else if(giveTaxStandard>14000000 && giveTaxStandard<=50000000){
      res = 15;
  }else if(giveTaxStandard>50000000 && giveTaxStandard<=88000000){
      res = 24;
  }else if(giveTaxStandard>88000000 && giveTaxStandard<=150000000){
      res = 35;
  }else if(giveTaxStandard>150000000 && giveTaxStandard<=300000000){
      res = 38;
  }else if(giveTaxStandard>300000000 && giveTaxStandard<=500000000){
      res = 40;
  }else if(giveTaxStandard>500000000 && giveTaxStandard<=1000000000){
      res = 42;
  }else if(giveTaxStandard>1000000000){
      res = 45;
  }
  res = Math.floor(res);
  if(res<0) res = 0;
  return NumToStr(res);
}
// 지방소득세, 총 납부할 세액 계산
export const calTotalTax = (giveTaxStandard, giveTariff) => {
  giveTaxStandard = strToNum(giveTaxStandard);
  giveTariff = strToNum(giveTariff);
  giveTariff = giveTariff / 100;
  let giveTariffCost = 0;        // 양도소득산출세액
  let localTax = 0;  // 지방소득세
  let finalTax = 0;    // 납부할 세액

  if(giveTaxStandard <= 14000000){
    giveTariffCost = (giveTaxStandard * giveTariff);
  }else if(giveTaxStandard>14000000 && giveTaxStandard<=50000000){
    giveTariffCost = (giveTaxStandard * giveTariff) - 1260000;
  }else if(giveTaxStandard>50000000 && giveTaxStandard<=88000000){
    giveTariffCost = (giveTaxStandard * giveTariff) - 5760000;
  }else if(giveTaxStandard>88000000 && giveTaxStandard<=150000000){
    giveTariffCost = (giveTaxStandard * giveTariff) - 15440000;
  }else if(giveTaxStandard>150000000 && giveTaxStandard<=300000000){
    giveTariffCost = (giveTaxStandard * giveTariff) - 19940000;
  }else if(giveTaxStandard>300000000 && giveTaxStandard<=500000000){
    giveTariffCost = (giveTaxStandard * giveTariff) - 25940000;
  }else if(giveTaxStandard>500000000 && giveTaxStandard<=1000000000){
    giveTariffCost = (giveTaxStandard * giveTariff) - 35940000;
  }else if(giveTaxStandard>1000000000){
    giveTariffCost = (giveTaxStandard * giveTariff) - 65940000;
  }
  localTax = giveTariffCost * 0.1;
  finalTax = giveTariffCost + localTax;

  giveTariffCost = NumToStr(Math.floor(giveTariffCost)); // 양도소득산출세액
  localTax = NumToStr(Math.floor(localTax)); // 지방소득세
  finalTax = NumToStr(Math.floor(finalTax)); // 총 납부할 세액

  return { giveTariffCost, localTax, finalTax }
}
export const calLimitDate = (giveYear, giveMonth) => {
  let limitYear = Number(giveYear);
  let limitMonth = Number(giveMonth)+2;
  let limitDay = 0;

  if(limitMonth > 12){
    limitYear = limitYear + 1;
    limitMonth = limitMonth-12;
  }
  if(limitMonth === 1 || limitMonth === 3 || limitMonth === 5 || limitMonth === 7 || limitMonth === 8 || limitMonth === 10 || limitMonth === 12){
    limitDay += 31;
  }
  if(limitMonth === 4 || limitMonth === 6 || limitMonth === 9 || limitMonth === 11){
    limitDay += 30;
  }
  if(limitMonth === 2){
    if((limitYear % 4) === 0 && (limitYear % 100) !== 0 || (limitYear % 400) === 0){
      limitDay += 29;
    }else{
      limitDay += 28;
    }
  }
  return { limitYear, limitMonth, limitDay }
}
/*// 과세양도차익
export const calGiveTaxProfit = (giveCost, giveProfit, detailGubun) => {
  giveCost = strToNum(giveCost);
  giveProfit = strToNum(giveProfit);
  detailGubun = strToNum(detailGubun);

  let res = 0;
  if(detailGubun === 'highOneHouse') res = giveProfit * ((giveCost - 1200000000) / giveCost);
  else res = giveProfit;

  if(res<0) res = 0;

  return Math.floor(res);
}*/





// 증여공제액 계산
export const calPropertyRefund = (relationship) => {
  let res = 0;
  switch (relationship) {
    case 'spouse':
      res = 600000000;
      break;
    case 'sonAdult':
      res = 50000000;
      break;
    case 'sonKid':
      res = 20000000;
      break;
    case 'grandsonAdult':
      res = 50000000;
      break;
    case 'grandsonKid':
      res = 20000000;
      break;
    case 'father':
      res = 50000000;
      break;
    case 'mother':
      res = 50000000;
      break;
    case 'daughterInLaw':
      res = 10000000;
      break;
    case 'sonInLaw':
      res = 10000000;
      break;
    case 'relative':
      res = 10000000;
      break;
  }
  return NumToStr(res);
}
// 과세표준액 계산
export const calPropertyTaxStandard = (totalProperty,totalDebt,propertyRefund) => {
  totalProperty = strToNum(totalProperty);
  totalDebt = strToNum(totalDebt);
  propertyRefund = strToNum(propertyRefund);

  let res = strToNum(totalProperty)-strToNum(totalDebt)-strToNum(propertyRefund);   //증여세과세표준 : 증여재산가액-채무가액-증여공제액
  if(res<0) res = 0;
  return NumToStr(res);
}
// 증여세율 계산
export const calPropertyTaxRate = (propertyTaxStandard) => {
  let res = 0;
  propertyTaxStandard = strToNum(propertyTaxStandard);
  if(propertyTaxStandard <= 100000000){
    res = 10;
  } else if(100000000 < propertyTaxStandard && propertyTaxStandard <= 500000000){
    res = 20;
  } else if(500000000 < propertyTaxStandard && propertyTaxStandard <= 1000000000){
    res = 30;
  } else if(1000000000 < propertyTaxStandard && propertyTaxStandard <= 3000000000){
    res = 40;
  } else if(propertyTaxStandard > 3000000000){
    res = 50;
  }
  return NumToStr(res);
}
// 증여 산출세액 계산
export const calPropertyTaxCost = (propertyTaxStandard) => {
  let res = 0;
  propertyTaxStandard = strToNum(propertyTaxStandard);
  if(propertyTaxStandard <= 100000000){
    res = propertyTaxStandard * 0.1;
  } else if(100000000 < propertyTaxStandard && propertyTaxStandard <= 500000000){
    res = (propertyTaxStandard * 0.2) - 10000000;
  } else if(500000000 < propertyTaxStandard && propertyTaxStandard <= 1000000000){
    res = (propertyTaxStandard * 0.3) - 60000000;
  } else if(1000000000 < propertyTaxStandard && propertyTaxStandard <= 3000000000){
    res = (propertyTaxStandard * 0.4) - 160000000;
  } else if(propertyTaxStandard > 3000000000){
    res = (propertyTaxStandard * 0.5) - 460000000;
  }
  res = Math.floor(res/10)*10;
  return NumToStr(res);
}
// 세대생략가산액 계산
export const calGenerationSkipCost = (relation,propertyTaxCost) => {
  let res = 0;
  propertyTaxCost = strToNum(propertyTaxCost);
  if(relation === 'grandsonAdult' || relation === 'grandsonKid'){
    res = propertyTaxCost * 0.3;
  }
  return NumToStr(res);
}
// 산출세액계 계산
export const calTotalGiftTax = (propertyTaxCost,generationSkipCost) => {
  propertyTaxCost = strToNum(propertyTaxCost);
  generationSkipCost = strToNum(generationSkipCost);
  let res = propertyTaxCost + generationSkipCost;
  return NumToStr(res);
}
// 신고세액공제 계산
export const calTotalTaxRefund = (totalTax) => {
  totalTax = Math.floor((strToNum(totalTax) * 0.03));
  let res = totalTax;
  return NumToStr(res);
}
// 납부할세액 계산
export const calFinalGiftTax = (totalTax,totalTaxRefund) => {
  totalTax = strToNum(totalTax);
  totalTaxRefund = strToNum(totalTaxRefund);
  let res = totalTax - totalTaxRefund;
  return NumToStr(res);
}
