import React from "react";
import { useNavigate } from "react-router-dom";

const Sources = () => {
  const navigate = useNavigate();

  return (
    <>
      <section className="common sources">
        <div className="w1756">
          <h2>자금출처</h2>
          <p>
            최근 확대되고 있는 다양한 형태의 <br className="br480" /> 세무조사의
            경험이 있는 세무사.
          </p>
          <figure>
            <img
              src="/images/sub_img_sources01.png"
              alt="자금출처"
              className="pc"
            />
            <img
              src="/images/sub_img_sources01_1300.png"
              alt="자금출처"
              className="w1300"
            />
            <img
              src="/images/sub_img_sources01_800.png"
              alt="자금출처"
              className="w800"
            />
            <img
              src="/images/sub_img_sources01_400.png"
              alt="자금출처"
              className="w400"
            />
          </figure>
        </div>
      </section>
      <section className="strategic">
        <div className="container">
          <h2>
            <span className="bold">금융기관 출신의 세무사</span>와
            <br className="br800" />
            <span className="bold"> 다양한 세무조사 대리의 경험</span>을
            바탕으로&nbsp;
            <br className="br420" />
            <span className="bold">정확한 전략 대응</span>으로 재산권을
            지켜드리겠습니다.
          </h2>
          <figure>
            <img
              src="/images/sub_img_sources02.png"
              alt="자금출처 전략"
              className="pc"
            />
            <img
              src="/images/sub_img_sources02_800.png"
              alt="자금출처 전략"
              className="w800"
            />
            <img
              src="/images/sub_img_sources02_400.png"
              alt="자금출처 전략"
              className="w400"
            />
          </figure>
        </div>
      </section>
      <section className="sourcesTxt">
        <div className="container">
          <div className="flex">
            <figure>
              <img
                src="/images/sub_img_sources03.png"
                alt="업무 이미지"
                className="pc"
              />
              <img
                src="/images/sub_img_sources03_800.png"
                alt="업무 이미지"
                className="w800"
              />
              <img
                src="/images/sub_img_sources03_400.png"
                alt="업무 이미지"
                className="w400"
              />
            </figure>
            <div className="txt">
              <h2>
                금융조사 확대와 변하는 세무환경에 <br />
                저희 한경은 철저히 대응합니다.
              </h2>
              <p>
                과거에는 사업자 및 부동산취득에 대한 조사가 주를
                이루었다면&nbsp;
                <br className="br1013" />
                최근에는 다양한 방법으로 조사대상자를 선정하여 조사가 더욱
                확대되고 있는 실정입니다. <br className="br1370" />
                최근 가장 특징적인 조사는 금융소득분석을 통한 금융재산 및
                금융거래내역 조사부터 주식취득에 대한 조사까지 그동안
                조사대상에서 제외되어 있던 금융관련 조사가 부쩍 늘고
                있습니다.&nbsp;
                <br className="br1460" />
                한경세무법인은 금융기관 출신의 세무사가 다양한 형태의 세무조사
                대리의 경험을 바탕으로 정확하고 전략적인 대응을 통해 고객의
                재산권이 부당하게 침해되는 일이 없도록 최선을 다하겠습니다.
              </p>
            </div>
          </div>
          <div className="cuBtn right">
            <button onClick={() => navigate("/community/customer")}>
              상담 문의하기
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sources;
