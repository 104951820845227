const SelectBox = ({ select, options, text='',style='w150',setDate=''}) => {
  const type = typeof select.value;

  const handleOnChange = (n,v) => {
    select.handleSelect(n,v);
  }
  const handleDateChange = (n,v) => {
    if(setDate !== ''){
      setDate(v);
      select.handleSelect(n,v);
    }
  }

    return(
      <div className={`select ${style}${select.isOpen ? ` active` : ''}`} ref={select.selectRef} onClick={() => {select.toggle()}} id="selectTag" tabIndex="0">
        <span>{type === "number" ? String(select.value).padStart(2,'0') : select.name}{text}</span>
        {select.isOpen &&
            <ul className="option_area">
              {options?.map(el => (
                  <li key={el.name} className="option" onClick={() => {
                    typeof select.value === "number" ? handleDateChange(el.name, el.value) : handleOnChange(el.name, el.value)
                  }}>{el.name}</li>
              ))}
            </ul>}
      </div>
  )
}

export default SelectBox;