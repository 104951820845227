import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Community from "./Community";
import TaxCalculator from "./TaxCalculator";

const ConsultaionInquiry = () => {
  const [isCalculation, setIsCalculation] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if(location?.pathname.split('/')[2] === 'taxCalculator'){
      setIsCalculation(true)
    } else {
      setIsCalculation(false);
    }
  }, [location?.pathname]);

  return (
      <section>
        <div className={isCalculation ? 'community_wrap' : 'community_wrap pb120'}>
          <h1 className="community_title">{isCalculation ? '세금계산기' : '상담문의'}</h1>
          <p className="community_content">
            세무 관련 궁금하신 사항에 대해 문의주시면, 전문 세무사가 답변을 드립니다.<br/>
            답변은 연락처 또는 이메일로 드리니 고객 정보를 정확하게 입력해 주시기 바랍니다.
          </p>
          <nav className="community_btn_wrap">
            <NavLink to="/community/taxCalculator" className={({isActive, isPending}) =>  isActive ? 'active' : ''}>
              세금계산기
            </NavLink>
            <NavLink to="/community/customer" className={({isActive, isPending}) =>  isActive ? 'active' : ''}>
              상담문의
            </NavLink>
          </nav>
          {isCalculation ? <TaxCalculator/> : <Community />}
        </div>
      </section>
  )
}
export default ConsultaionInquiry;