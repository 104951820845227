import { useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { throttle } from "lodash";
import "scss/common.scss";
import "./App.css";
import "scss/style.scss";
import "scss/scrollBar.scss";
import "scss/style2.scss";
import Header from "components/header/Header";
import Main from "pages/main/Main";
import ImageSlider from "./pages/main/include/ImageSlider";
import AccountBook from "./pages/accountBook/accountBook";
import InheritanceTax from "./pages/inheritanceTaxService/InheritanceTax";
import CapitalGains from "./pages/capitalGainsTax/capitalGains";
import ConsultationInquiry from "./pages/community/ConsultationInquiry";
import Footer from "./components/footer/Footer";
import TermPersonal from "./pages/popup/TermPersonal";
import GiftTax from "./pages/giftTax/giftTax";
import Sources from "./pages/fundingSource/sources";
import UserAbout from "./pages/about/userAbout";

function App() {
  const { pathname } = useLocation();
  const [scroll, setScroll] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [bgActive, setBgActive] = useState(false);
  const [noWheel, setNoWheel] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const menuList = ['/','/accountBook','/inheritanceTax','/giftTax','/transferIncomeTax','/sources','/about','/community/taxCalculator',
  '/community/customer','/popup/termPersonal']
  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (window.scrollY !== 0) {
          setNoWheel(false);
        } else {
          setNoWheel(true);
        }
      }, 100),
    [noWheel]
  );

  /*  useEffect(() => {
  useEffect(() => {
    if (pathname === "/") {
      setIsFixed(false);
    } else {
      setIsFixed(true);
    }
  }, [pathname]);*/

  useEffect(() => {
    if (pathname === "/transferIncomeTax") {
      setBgActive(true);
    } else {
      setBgActive(false);
    }
    if(menuList.find(el => el === pathname) === undefined){
      console.log(menuList.find(el => el === pathname));
      setNotFound(true)
    } else {
      setNotFound(false);
    };
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("scroll", throttledScroll);
    return () => {
      window.removeEventListener("scroll", throttledScroll);
    };
  }, [throttledScroll]);

  return (
    <>
      <Header noWheel={noWheel} notFound={notFound} />
      <main>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/accountBook" element={<AccountBook />} />
          <Route path="/inheritanceTax" element={<InheritanceTax />} />
          <Route path="/giftTax" element={<GiftTax />} />
          <Route path="/transferIncomeTax" element={<CapitalGains />} />
          <Route path="/sources" element={<Sources />} />
          <Route path="/about" element={<UserAbout />} />
          <Route path="/accountBook" element={<AccountBook />}></Route>
          <Route path="/inheritanceTax" element={<InheritanceTax />}></Route>
          <Route path="/transferIncomeTax" element={<CapitalGains />}></Route>
          <Route path="/community/taxCalculator" element={<ConsultationInquiry />}></Route>
          <Route path="/community/customer" element={<ConsultationInquiry />}></Route>
          <Route path="/popup/termPersonal" element={<TermPersonal />} />
          <Route path="/*" element={<Main />} />
        </Routes>
      </main>
      <Footer noWheel={noWheel} bgActive={bgActive} />
    </>
  );
}

export default App;
