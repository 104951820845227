import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider = ({setCurrentIdx, slideRef}) => {
  const settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentIdx(newIndex);
    }
  };

  return (
      <>
        <Slider {...settings} className="custom_slider" ref={slideRef}>
          <div className="img first"></div>
          <div className="img second"></div>
          <div className="img third"></div>
        </Slider>
      </>
  )
}

export default ImageSlider;