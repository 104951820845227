export const NavData = [
  {
    id: 1,
    link: '/accountBook',
    title: '기장서비스'
  },
  {
    id: 2,
    link: '/inheritanceTax',
    title: '상속세서비스'
  },
  {
    id: 3,
    link: '/giftTax',
    title: '증여세서비스'
  },
  {
    id: 4,
    link: '/transferIncomeTax',
    title: '양도소득세서비스'
  },
  {
    id: 5,
    link: '/sources',
    title: '자금출처'
  },
  {
    id: 6,
    link: '/about',
    title: '회사소개'
  },
  {
    id: 7,
    link: '/community/taxCalculator',
    title: '커뮤니티'
  },
]
